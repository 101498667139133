import * as React from 'react';
import StyleNormalizer from './StyleNormalizer';
import { ThemeProvider, theme } from './theme';

const GlobalCollection = ({ children }) => {
  return (
    <ThemeProvider theme={theme.default}>
      <link href="https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap" rel="stylesheet" />
      <StyleNormalizer />
      {children}
    </ThemeProvider>
  );
};

export default GlobalCollection;
