import paletteTheme from './paletteTheme';
import generalTheme from './generalTheme';

const theme = {
  default: {
    general: generalTheme,
    palette: paletteTheme,
  },
};

export default theme;
