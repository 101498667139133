const paletteTheme = {
  danger: {
    shade1: '#FEE4E3',
    shade2: '#F8C7C5',
    shade3: '#F78B87',
    shade4: '#DF5858',
    shade5: '#B22A2A',
    shade6: '#A01818',
    shade7: '#7F0D0D',
    shade8: '#5F0505',
    shade9: '#440404',
  },
  neutral: {
    shade0: '#ffffff',
    shade1: '#F5F7FD',
    shade2: '#ECF1F8',
    shade3: '#DFE4EB',
    shade4: '#AFB8C4',
    shade5: '#8892A0',
    shade6: '#686B82',
    shade7: '#484C60',
    shade8: '#2C353F',
    shade9: '#070b10',
  },
  primary: {
    shade4: '#00ae96',
  },
  secondary: {
    shade0: '#EDF5FF',
    shade1: '#E1EDFB',
    shade2: '#ACC5E1',
    shade3: '#5180B7',
    shade4: '#1E3B5C',
  },
  success: {
    shade1: '#E3FCEF',
    shade2: '#BCF7D7',
    shade3: '#8ADCB0',
    shade4: '#46C384',
    shade5: '#188A50',
    shade6: '#19663F',
    shade7: '#0A4D2B',
    shade8: '#033B1E',
    shade9: '#002B15',
  },
  warning: {
    shade1: '#FFFFD8',
    shade2: '#FFFAA5',
    shade3: '#FFF280',
    shade4: '#F6DE62',
    shade5: '#F6D636',
    shade6: '#F6C636',
    shade7: '#EEAE18',
    shade8: '#E09C16',
    shade9: '#C57600',
  },
};

export default paletteTheme;
